import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBJT7d_u67VBvz9CYwAVc2kbWrBT0bn4P0",
  authDomain: "bird-software-llc-site.firebaseapp.com",
  projectId: "bird-software-llc-site",
  storageBucket: "bird-software-llc-site.appspot.com",
  messagingSenderId: "227815872655",
  appId: "1:227815872655:web:87d8cc4794fdc21c2bf8fa",
  measurementId: "G-E8T5P6N4WE"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAnalytics = getAnalytics(firebaseApp);

export { firebaseApp, firebaseAnalytics };