import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { firebaseAnalytics } from './firebase';
import { logEvent } from 'firebase/analytics';

function App() {
  useEffect(() => {
    logEvent(firebaseAnalytics, `landing_page_visited`);
  }, []);

  return (
    <div className="App">
      {/* Navigation */}
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="#home">Bird Software LLC</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="#apps">Apps</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link href="#contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Hero Section */}
      <section className="hero-section text-center">
        <Container>
          <Row className="align-items-center justify-content-center" style={{ height: '100vh' }}>
            <Col md={8}>
              <h1 className="text-light">Welcome to Bird Software LLC</h1>
              <p className="subtitle text-primary">Building iOS apps since 2023</p>
              <p className="text-light">
                At Bird Software LLC, we create financial software that enhances productivity and promotes financial literacy. 
                Our current offerings includes My Paycheck Calculator, with more apps on the way.
              </p>
              <p className="text-light">Stay tuned as we continue to develop our suite of apps!</p>
              <Button href="https://apps.apple.com/us/developer/jess-phillips/id1036074070" variant="primary" className="mt-3">Download Our Apps</Button>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Apps Section */}
      <section id="apps" className="apps-section">
        <Container>
          <Row>
            <Col className="text-center">
              <h2 className="text-light">Apps</h2>
              <div className="apps-item mt-4">
                <h3 className="text-light">My Paycheck Calculator</h3>
                <Image 
                  src="https://via.placeholder.com/400x300" 
                  alt="My Paycheck Calculator screenshot" 
                  className="app-screenshot"
                  fluid
                  rounded
                />
                <p className="mt-3 text-light">
                  A handy tool designed to help users easily calculate their net pay, including tax deductions and other details.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* About Section */}
      <section id="about" className="about-section">
        <Container>
          <Row>
            <Col className="text-center">
              <h2 className="text-light">About Us</h2>
              <p className="mt-4 text-light">
              Bird Software LLC is a software development company based in Colorado, specializing in user-friendly financial tools. 
              Our goal is to help users become more financially literate and productive through intuitive, easy-to-use apps. 
              Whether you're tracking your paycheck or planning future financial tools, we design software to fit seamlessly into your life.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact-section">
        <Container>
          <Row>
            <Col className="text-center">
              <h2 className="text-light">Contact Us</h2>
              <p className="mt-4 text-light">
                Email us at <a href="mailto:trip@birdsoftwarellc.com">trip@birdsoftwarellc.com</a>.
              </p>
              <p className="mt-4 text-light">
              1942 Broadway St. STE 314C, Boulder, Colorado 80302
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Footer */}
      <footer className="footer">
        <Container>
          <Row>
            <Col className="text-center">
              <p className="text-light">© {new Date().getFullYear()} Bird Software LLC. All rights reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default App;
